import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_ACTIVE_USER } from "../../queries/auth.queries";
import Loader from "../common/loader/loader.component";
import AccountAddresses from "./account-addresses/account-addresses.component";
import AccountBankCards from "./account-bank-cards/account-bank-cards.component";
import AccountMenu from "./account-menu/account-menu.component";
import AccountOrders from "./account-orders/account-orders.component";
import AccountSettings from "./account-settings/account-settings.component";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../common/breadcrumbs/breadcrumbs.component";
import { ReactComponent as BreadCrumbsArrow } from "../../assets/rightArrow.svg";
import { ReactComponent as BreadCrumbsArrowLeft } from "../../assets/leftArrow.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    paddingTop: 120,
  },
  profileContainer: {
    paddingTop: 12,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 120,
  },
  menu: {
    width: "15%",
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  page: {
    width: "82%",
    maxWidth: 1130,
    "@media (max-width:940px)": {
      width: "100%",
    },
  },
});

const pages = {
  Orders: <AccountOrders />,
  Addresses: <AccountAddresses />,
  "Bank Cards": <AccountBankCards />,
  Settings: <AccountSettings />,
};

const Account = () => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const [page, setPage] = useState("Orders");

  const setCurrentPage = (p) => {
    setPage(p);
  };

  const history = useHistory();
  const state = location?.state;

  useEffect(() => {
    setPage(state?.page);
  }, [state]);

  useEffect(() => {
    setPage("Orders");
  }, []);

  const { loading: userLoading, data: userData } = useQuery(GET_ACTIVE_USER);

  if (userLoading) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Loader color={"#B73232"} size={50} />
      </div>
    );
  }

  if (userData && !userData.activeCustomer) {
    history.push("/");
  }

  const currentPage = pages[page];

  const onGoBack = () => {
    history.push("/catalog");
  };
  const dir = t("css.side");

  return (
    <div className={classes.container}>
      <div style={{ display: "block" }}>
        <Breadcrumbs prevPage={t("itemPage.catalog")} onGoBack={onGoBack}>
          {dir === "ltr" ? (
            <BreadCrumbsArrow style={{ margin: "0 16px 0 16px" }} />
          ) : (
            <BreadCrumbsArrowLeft style={{ margin: "0 16px 0 16px" }} />
          )}
          <span className="h6-regular">{"Profile"}</span>
        </Breadcrumbs>
      </div>
      <div className={classes.profileContainer}>
        <div className={classes.menu}>
          <AccountMenu setCurrentPage={setCurrentPage} />
        </div>
        <div className={classes.page}>{currentPage}</div>
      </div>
    </div>
  );
};

export default Account;
