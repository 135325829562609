import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import FormInput from "../../common/form-input/form-input.component";

import "./auth-register.component.css";
import { validateEmail } from "../../../utils/validate-email.util";
import {
  GET_ACTIVE_USER,
  LOGIN,
  REGISTER_USER,
} from "../../../queries/auth.queries";
import { useHistory } from "react-router-dom";
import Loader from "../../common/loader/loader.component";
import { h5Medium, pointer } from "../../../shared/constants";
import { isErrorLogin, isErrorRegister } from "../auth.util";
import { AccountContext } from "../../../context/account.context";
import { ReactComponent as VisibilityIcon } from "../../../assets/visibility-icon.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/visibility-off-icon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputField: {
    width: "100%",
  },
  button: {
    height: 56,
    width: "100%",
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
}));

const AuthRegister = ({ handleClose, route = "/catalog" }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();

  const [addressDetails, setAddressDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const [errorMsg, setErrorMsg] = useState({
    isError: false,
    errorMessage: "",
  });

  const { isError, errorMessage } = errorMsg;

  const [erros, setErros] = useState({
    emailMessage: "",
    passwordMessage: "",
  });

  const { accountState, setAccountState } = useContext(AccountContext);

  const { emailMessage, passwordMessage } = erros;

  const { email, password } = addressDetails;

  let [register, { loading: registerLoaing }] = useMutation(REGISTER_USER, {
    variables: {
      emailAddress: email,
      password: password,
    },
    refetchQueries: [
      { query: GET_ACTIVE_USER }, // Query name
    ],
  });

  let [authLogin, { loading: loadingLogin }] = useMutation(LOGIN, {
    variables: {
      username: email,
      password: password,
      rememberMe: true,
    },
    refetchQueries: [
      { query: GET_ACTIVE_USER }, // Query name
    ],
    awaitRefetchQueries: true,
  });

  const redirectUser = () => {
    history.push(route);
  };

  if (registerLoaing || loadingLogin)
    return <Loader color={"#B73232"} size={70} />;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // setErrorMsg({
    //   isError: false,
    //   errorMessage: "",
    // });

    const { value, name } = e.target;

    if (value && !value.length) {
      setErros({
        ...erros,
        [`${name}Message`]: `${name} is required`,
        [`${name}Error`]: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      setErros({
        ...erros,
        emailError: true,
        emailMessage: "Inalid email address",
      });
      return;
    }

    try {
      const res = await register();

      if (isErrorRegister(res.data?.registerCustomerAccount.__typename)) {
        setErrorMsg({
          isError: true,
          errorMessage: res.data?.registerCustomerAccount.message,
        });
        return;
      }

      const autoLogin = await authLogin();

      if (isErrorLogin(autoLogin.data?.login.__typename)) {
        setErrorMsg({
          isError: true,
          errorMessage: autoLogin.data?.login.message,
        });
        return;
      }

      setAccountState({
        ...accountState,
        isUser: true,
      });

      handleClose();
      redirectUser();
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setAddressDetails({ ...addressDetails, [name]: value });

    if (!value || !value.length) {
      setErros({
        ...erros,
        [`${name}Message`]: `${name} is required`,
        [`${name}Error`]: true,
      });
    } else {
      setErros({
        ...erros,
        [`${name}Message`]: "",
        [`${name}Error`]: false,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="string"
          value={addressDetails.email}
          handleChange={handleChange}
          label={t("auth.Email")}
          message={emailMessage}
          required
        />
        <div style={{ position: "relative" }}>
          <FormInput
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={addressDetails.password}
            label={t("auth.Password")}
            handleChange={handleChange}
            message={passwordMessage}
            required
            margin="45px 0 0 0"
          />
          <span onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: 12,
              top: 10,
              cursor: pointer,
              color: "#15222F",
              opacity: 0.8
            }}>
            {showPassword ? <div><VisibilityIcon /></div> : <div><VisibilityOffIcon /></div>}
          </span>
        </div>

        <div className="buttons" style={{ marginTop: 44 }}>
          <button type="submit" className={classes.button}>
            {t("auth.Register")}
          </button>
        </div>
        <pre>
          {isError ? (
            <span className="AL__errors h6-medium">{errorMessage}</span>
          ) : (
            ""
          )}
        </pre>
      </form>
    </React.Fragment>
  );
};

export default AuthRegister;
