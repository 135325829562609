import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

import { GET_ORDER_BY_CODE } from "../../../queries/cart.queries";
import {
  h4Medium,
  h5Semibold,
  h6Medium,
  h6Regular,
  h7Regular,
  pointer,
  h5Medium,
} from "../../../shared/constants";
// import { ReactComponent as HeartIcon } from "../../../assets/heart.svg";
import { ReactComponent as BasketIcon } from "../../../assets/basketadd.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as DeliveryTruckIcon } from "../../../assets/deliveryTruck.svg";
// import { ReactComponent as CreditCardIcon } from "../../../assets/credit-card.svg";
import { ReactComponent as ScheduleIcon } from "../../../assets/schedule.svg";
// import { ReactComponent as RecurringIcon } from "../../../assets/recurring.svg";
import Loader from "../../common/loader/loader.component";
import OrderTimeLine from "../../common/order-time-line/order-time-line.component";
import AccountCancelOrder from '../account-cancel-order/account-cancel-order.jsx';
import Currency from '../../../shared/components/currency/Currency';
import { getDateInfo } from "../../../shared/dates";

const useStyles = makeStyles(() => ({
  container: ({ dir }) => ({
    width: 1064,
    maxHeight: "95%",
    overflow: "auto",
    borderRadius: 32,
    display: "flex",
    border: "none",
    position: "relative",
    background:
      dir === "ltr"
        ? "linear-gradient(to right, #FFFFFF 60%, #ECFFDC 40%)"
        : "linear-gradient(to left, #FFFFFF 60%, #ECFFDC 40%)",
  }),

  header: {
    background: "#FFFFFF",
    width: "65%",
    padding: 48,
    height: "100%"
  },

  subHeader: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
  },

  lines: {
    marginTop: 40,
    borderTop: "1px dashed #15222F",
    overflow: "auto",
  },

  line: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 24,
    borderBottom: "1px dashed #15222F",
  },
}));

const AccountDisplayOrder = ({
  code,
  handleClose,
  onAddLineToBasket,
  onAddToBasket,
  addToCardLoader
}) => {
  const { t } = useTranslation();
  const dir = t("css.side");
  const [idSelected, setIdSelected] = useState(null);

  const { loading, error, data: orderData } = useQuery(GET_ORDER_BY_CODE, {
    variables: { code },
    fetchPolicy: "no-cache"
  });

  const classes = useStyles({ dir });

  if (loading) return <Loader color={"#B73232"} size={70} />;

  if (error) {
    console.log(error);
  }

  const data = orderData?.orderByCode;
  let dateFromatted = '';
  let timeRange = { from: "", to: "" };

  const addressData = data?.shippingAddress;
  const crossIconPosition = dir === "ltr" ? { right: 48 } : { left: 48 };

  const setFormattedDate = () => {
    if (orderData?.orderByCode?.timeWindow) {
      const { description } = orderData?.orderByCode?.timeWindow;
      const [from, to] = description?.split(" - ")
      timeRange = { from, to };
    }
    if (data?.deliveryTime) {
      const { customFormattedDate } = getDateInfo(data?.deliveryTime, dir === "ltr" ? 'en' : 'he');
      dateFromatted = customFormattedDate;
    }
  };

  setFormattedDate();

  return (
    <div className={classes.container} style={{ direction: dir }}>
      <div
        className={classes.header}>
        <span style={{ ...h4Medium, opacity: 0.8 }}>
          {t("account.order")} #{code}
        </span>
        <div className={classes.subHeader}>
          <OrderTimeLine state={data?.state} />
        </div>
        <div className={classes.lines}>
          {data?.lines.map((line) => (
            <div className={classes.line} key={line?.id}>
              <div style={{ height: 56, width: 56 }}>
                <img
                  src={line?.featuredAsset?.preview}
                  alt={line?.featuredAsset?.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 8,
                  }}
                />
              </div>
              <span style={{ maxWidth: 168, width: "100%", ...h6Regular }}>
                {line?.productVariant?.name}
              </span>
              {/* <Tooltip title={`${t("account.addToWishList")}`} placement="top">
                <HeartIcon
                  style={{
                    ...pointer,
                  }}
                />
              </Tooltip> */}

              <Tooltip
                title={`${t("account.copyLineToBasket")}`}
                placement="top"
              >
                <div
                  style={{
                    height: 56,
                    width: 56,
                    background: "#80BB34",
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...pointer,
                  }}
                  onClick={() => {
                    setIdSelected(line?.id);
                    onAddLineToBasket(line);
                  }}
                >
                  {idSelected === line?.id && addToCardLoader ? (
                    <Loader color={"#B73232"} size={30} />
                  ) : (
                    <BasketIcon />
                  )}
                </div>
              </Tooltip>

              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ ...h7Regular, marginBottom: 4 }}>
                  {t("account.Qty")}
                </span>
                <span
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#15222F",
                    opacity: 0.64,
                  }}
                >
                  {line?.quantity}
                </span>
              </span>
              <span style={{ ...h6Medium }}>
                <Currency />
                {(line?.unitPriceWithTax / 100).toFixed(2)}
              </span>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 26,
          }}
        >
          <span style={{ ...h6Medium, opacity: 0.64 }}>
            {t("account.totalProducts")}
          </span>
          <span style={{ ...h6Medium, opacity: 0.72 }}>
            <Currency /> {(data?.subTotalWithTax / 100).toFixed(2)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 26,
          }}
        >
          <span style={{ ...h6Medium, opacity: 0.64 }}>
            {t("account.delivery")}
          </span>
          <span style={{ ...h6Medium, opacity: 0.72 }}>
            <Currency />
            {(data?.shipping / 100).toFixed(2)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 26,
          }}
        >
          <span style={{ ...h6Medium, opacity: 0.64 }}>
            {t("common.total")}
          </span>
          <span style={{ ...h5Semibold, opacity: 0.8 }}>
            <Currency /> {(data?.totalWithTax / 100).toFixed(2)}
          </span>
        </div>
      </div>
      <div
        style={{
          width: "35%",
          padding: 48,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%"
        }}
      >
        <div className="pointer">
          <RemoveIcon
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              ...crossIconPosition,
              ...pointer,
              zIndex: 50,
            }}
            onClick={handleClose}
          />
        </div>

        <div style={{ marginBottom: 56 }}>
          <div
            style={{
              display: "flex",
              marginBottom: 24,
            }}
          >
            <DeliveryTruckIcon
              style={{
                width: 20,
                height: 20,
                marginRight: dir === "ltr" ? 18 : 0,
              }}
            />
            <span style={{ ...h5Medium }}>{t("account.deliveryTo")}</span>
          </div>
          <div
            style={{
              ...h6Regular,
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{data?.customer?.firstName} {data?.customer?.lastName}</span>
            <span>{addressData?.streetLine1}</span>
            <span>
              {t("common.phone")}: {addressData?.phoneNumber}
            </span>
          </div>
        </div>

        <div style={{ marginBottom: 56, flex: 1 }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <ScheduleIcon
              style={{
                width: 20,
                height: 20,
                marginRight: dir === "ltr" ? 18 : 0,
              }}
            />
            <span style={{ ...h5Medium }}>{t("account.deliverySchedule")}</span>
          </div>
          <span
            style={{
              ...h6Regular,
              marginTop: 16,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {`${dateFromatted} ${t('checkout.fromTo', { startTime: timeRange?.from, endTime: timeRange?.to })}`}
          </span>
        </div>

        {data?.state === 'Open' ?
          <div>
            <AccountCancelOrder order={data} handleCloseParentModal={handleClose} />
          </div>
          : null}
      </div>
    </div>
  );
};

export default AccountDisplayOrder;
