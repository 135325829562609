import { makeStyles } from "@material-ui/core";
import React from "react";
import { h6Medium } from "../../../shared/constants";
import OrderTimeLine from "../../common/order-time-line/order-time-line.component";
import Loader from "../../common/loader/loader.component";
import { useTranslation } from "react-i18next";
import Currency from '../../../shared/components/currency/Currency';

const useStyles = makeStyles({
  container: {
    borderRadius: 16,
    height: "auto",
    background: "#FFFFFF",
    marginBottom: 16,
    padding: 24,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },

  linesContainer: {
    paddingTop: 16,
    marginTop: 16,
    borderTop: "1px dashed rgba(21, 34, 47, 0.08)",
    display: "flex",
    gap: 6
  },

  lineItem: {
    width: 32,
    height: 32,
    border: "none",
    borderRadius: 8,
    objectFit: "cover"
  }
});

const AccountOrderRes = ({ orders, addToCardLoader, onAddToBasket, setIndex, index, onCancelOrder, cancelOrderLoader }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return orders.length
    ? orders.map((order, i) => {
      return (
        <div className={classes.container} key={i}>
          <div className={classes.header}>
            <span style={{ ...h6Medium }}>
              {order?.createdAt.substring(0, 10)}
            </span>
            <span style={{ ...h6Medium }}>
              <Currency /> {(order?.totalWithTax / 100).toFixed(2)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              ...h6Medium,
              marginBottom: 16,
              justifyContent: "center",
            }}
          >
            <OrderTimeLine state={order?.state} />
          </div>
          {order?.state !== "Cancelled" ? <div
            style={{
              color: "#80BB34",
              ...h6Medium,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10, height: 50 }}>
              {addToCardLoader && i === index ?
                <Loader color={"#B73232"} size="15" />
                : <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(i);
                    onAddToBasket(order);
                  }}>
                  {t("account.Add to basket")}
                </span>}
              {order?.state === 'Open' ?
                <>
                  {cancelOrderLoader ?
                    <Loader color={"#B73232"} size="15" />
                    : <span style={{ color: "#f50050", cursor: "pointer" }} onClick={() => { onCancelOrder(order?.id) }}>Cancel Order</span>}
                </> : null}
            </div>
          </div> : null}
          <div className={classes.linesContainer}>{
            order?.lines?.map(line => (<img
              src={line?.featuredAsset?.preview}
              alt={line?.featuredAsset?.name}
              className={classes.lineItem}
            />
            ))
          }</div>
        </div>
      );
    })
    : "No Products Available";
};

export default AccountOrderRes;
