import React from 'react';
import {
    h6Regular,
    h6Semibold,
} from "../../../../shared/constants";

import './search-list-item.css';
import Currency from '../../currency/Currency';

const SearchListItem = ({ item }) => {
    return <div className='search-list-item'>
        <div className='search-list-item__description'>
            <img className='search-list-item__img' src={item?.productAsset?.preview} alt={item?.productAsset?.id} />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ ...h6Semibold, opacity: 0.64 }}>{item?.productName}</span>
                <span style={{ ...h6Regular, opacity: 0.64 }}>{item?.productVariantName ?? ''}</span>
            </div>
        </div>
        <div className='search-list-item__price h6-medium'>
            <Currency />{(item?.priceWithTax?.value / 100).toFixed(2)}
        </div>
    </div>
};

export default SearchListItem;