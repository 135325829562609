import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { ReactComponent as RemoveIcon } from "../../../assets/cart-cross.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/empty.svg";
import {
  h4Light,
  h4Medium,
  h5Light,
  h5Medium,
  h5Semibold,
  h6Medium,
  h7Medium,
  pointer,
  h7Regular
} from "../../../shared/constants";
import CartItem from "../cart-item/cart-item.component";
import {
  GET_ACTIVE_ORDER,
  REMOVE_ALL_LINES_FROM_CART
} from "../../../queries/cart.queries";
import { useMutation } from "@apollo/client";
import Loader from "../../common/loader/loader.component";
import Currency from '../../../shared/components/currency/Currency';

const useStyles = makeStyles({
  container: {
    padding: "44px 40px",
    width: 680,
    background: "#FFFFFF",

    "@media (max-width:940px)": {
      padding: "20px 12px",
      width: 375,
    },

    "@media (max-width:380px)": {
      padding: "20px 12px",
      width: 250,
    },
  },

  header: {
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    paddingBottom: 20,

    "@media (max-width:940px)": {
      height: 32,
    },
  },

  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: 230,

    "@media (max-width:940px)": {
      width: 180,
    },
  },
  headerBasket: {
    ...h4Medium,

    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },
  headerWish: {
    ...h4Light,
    opacity: 0.64,

    "@media (max-width:940px)": {
      ...h5Light,
      opacity: 0.64,
    },
  },

  closeIcon: {
    width: 32,
    height: 32,
    cursor: pointer,

    "@media (max-width:940px)": {
      width: 16,
      height: 16,
    },
  },

  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 42,
    height: 368,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",

    "@media (max-width:940px)": {
      marginTop: 24,
    },
  },

  title: {
    height: 56,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  empty: {
    ...h6Medium,
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    width: 310,
    height: 56,
    background: "#80BB34",
    borderRadius: 16,
    color: "#ffffff",
    textDecoration: "none",
    ...h5Medium,

    "@media (max-width:940px)": {
      maxWidth: 200
    },
  },

  total: {
    marginTop: 26,
    display: "flex",
    alignItems: "center",

    "@media (max-width:940px)": {
      marginRight: 0,
      position: "sticky",
      bottom: 0,
      border: "none",
      padding: 24,
      background: "#FFF"
    },
  },

  totalTitle: {
    ...h6Medium,
    opacity: 0.8,

    "@media (max-width:940px)": {
      ...h7Medium,
    },
  },

  totalNumber: {
    ...h5Semibold,
    opacity: 0.72,
  },

  extraDivForStyleTotalSection: {
    flex: 1,
    "@media (max-width:940px)": {
      display: "none"
    }
  },

  totalPriceSide: {
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width:940px)": {
      flexDirection: "column",
      alignItems: "flex-end"
    }
  },

  cartDrawerHeaderActions: {
    display: "flex",
    justifyContent: "flex-end"

  },
  cartDrawerClearAll: {
    opacity: 0.4,
    ...h7Regular,
    ...pointer
  }
});

const CartDrawer = ({ toggleCart }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { data, loading } = useQuery(GET_ACTIVE_ORDER);

  let lines = [];
  if (data && data.activeOrder) {
    lines = data.activeOrder.lines;
  }

  const header = (
    <div className={classes.header}>
      <div className={classes.headerTitles}>
        <span className={classes.headerBasket}>
          {`${t("cart.Basket")} ${data?.activeOrder?.totalQuantity}`}
        </span>
        {/* <span className={classes.headerWish}>{t("cart.Wishlist")} 0</span> */}
      </div>
      <div className="pointer" onClick={toggleCart}>
        <RemoveIcon className={classes.closeIcon} />
      </div>
    </div>
  );

  const [OnClearBasket, { loading: isLoading }] = useMutation(
    REMOVE_ALL_LINES_FROM_CART,
    {
      refetchQueries: [{ query: GET_ACTIVE_ORDER }],
    }
  );

  const headerActions = (
    <div className={classes.cartDrawerHeaderActions} style={{ direction: t("css.side") }}>
      {isLoading ? (
        <div style={{ width: "10%" }}>
          <Loader color="#B73232" size={13} />
        </div>
      ) : (
        <>
          <div></div>
          <div
            onClick={() => {
              OnClearBasket();
            }}
            className={classes.cartDrawerClearAll}
          >
            {t("cart.clearBasket")}
          </div>
        </>
      )}
    </div>
  );

  const onCartItemClicked = (item) => {
    history.push(
      {
        pathname: `/item/${item?.productVariant?.product?.slug}`,
        state: {
          variant: item?.productVariant,
        },
      }
    );
    toggleCart();
  }


  const cartLists = (
    <>
      {lines?.length ? (
        <>
          {lines.map((item) => (
            <CartItem key={item.id} item={item} onCartItemClicked={onCartItemClicked} />
          ))}
          <div className={classes.total}
            style={{
              direction: t("css.side"),
            }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 26, flex: 3 }}>
              <span style={{ ...h6Medium, opacity: 0.64 }}>{t("account.totalProducts")}</span>
              <span style={{ ...h6Medium, opacity: 0.64 }}>{t("account.deliveryTo")}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 26, flex: 2, alignItems: "flex-end" }}>
              <span style={{ ...h5Medium, opacity: 0.72 }}>
                <Currency />{(data?.activeOrder?.subTotalWithTax / 100).toFixed(2)}
              </span>
              <span style={{ ...h5Medium, opacity: 0.72 }}>
                <Currency />{(data?.activeOrder?.shippingWithTax / 100).toFixed(2)}
              </span>
            </div>
            <div className={classes.extraDivForStyleTotalSection}></div>
          </div>
          <div
            className={classes.total}
            style={{
              direction: t("css.side"),
            }}
          >
            <div style={{
              flex: 3
            }}>
              <Link
                to="/checkout"
                className={classes.button}
                onClick={toggleCart}
              >
                {t("checkout.title")}
              </Link>
            </div>
            <div style={{ flex: 2, alignItems: "center", justifyContent: "space-between" }}>
              <div className={classes.totalPriceSide}>
                <span className={classes.totalTitle}>{t("common.total")}</span>
                <span className={classes.totalNumber}>
                  <Currency />{(data?.activeOrder?.totalWithTax / 100).toFixed(2)}
                </span>
              </div>
            </div>
            <div className={classes.extraDivForStyleTotalSection}></div>
          </div>
        </>
      ) : (
        <EmptyCart toggleCart={toggleCart} />
      )}
    </>
  );

  if (loading) {
    return <div className={classes.container} style={{ height: "100%" }}>
      <Loader color={"#B73232"} size={50} />
    </div>
  }
  return (
    <div className={classes.container}>
      {header}
      {lines.length > 0 && headerActions}
      {cartLists}
    </div>
  );
};

const EmptyCart = ({ toggleCart }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.emptyContainer}>
      <div className={classes.title}>
        <span className={classes.empty}>{t("cart.Empty")}</span>
        <span className={classes.empty}>{t("cart.Check our catalog")}</span>
      </div>
      <EmptyIcon />
      <Link to="/catalog" className={classes.button} onClick={toggleCart}>
        {t("itemPage.catalog")}
      </Link>
    </div>
  );
};

export default CartDrawer;
