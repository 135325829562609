import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Parcel from "../Parcel";
import { GET_TIME_WINDOWS } from "../../../queries/time-widows.query";
import {
  h5Medium,
  h6Medium,
  pointer,
  h6Regular,
  getShortMonthAndDay,
  h4Medium,
  flex,
} from "../../../shared/constants";
import { h3 } from "../../../shared/constants";
import { ReactComponent as ScheduleIcon } from "../../../assets/schedule.svg";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { GET_ACTIVE_ORDER } from "../../../queries/cart.queries";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { useTranslation } from "react-i18next";
import DeliveryScheduler from "../delivery-scheduler/delivery-scheduler.component";
import { SET_ORDER_TIME_WINDOW } from "../../../mutations/set-order-time-window";
import Loader from "../../common/loader/loader.component";
import { ErrorHandlingContext } from "../../../context/error-handling.context";
import { ReactComponent as EmptyIcon } from "../../../assets/empty.svg";
import { Link } from "react-router-dom";
import UnavailableDeliveryItems from '../unavailable-delivery-items/unavailable-delivery-items';
import { formatDeliveryDate } from "../Parcel/Parcel.helper";
import { getDateInfo } from "../../../shared/dates";

const useStyles = makeStyles({
  button: {
    height: 56,
    width: 240,
    border: "none",
    textAlign: "center",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 42,
    height: 368,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",

    "@media (max-width:940px)": {
      marginTop: 24,
    },
  },

  title: {
    height: 56,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  empty: {
    ...h6Medium,
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    width: 310,
    height: 56,
    background: "#80BB34",
    borderRadius: 16,
    color: "#ffffff",
    textDecoration: "none",
    ...h5Medium,

    "@media (max-width:940px)": {
      maxWidth: 200,
    },
  },
});

const DeliveryProcess = ({ setProcesses, processes, onEditDeliveryTime }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setErrorHandlingState } = useContext(ErrorHandlingContext);

  const { stage3DeliverTo } = processes;

  const [closeAccordion, setCloseAccordion] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pickDays, setPickDays] = useState(0);
  const [hourPicked, onHourPicked] = useState({ 0: '1' });
  const [date, setDate] = useState(null);
  const [timeDescription, setTimeDescription] = useState(null);
  const [orderLines, setOrderLines] = useState([]);
  const [timesAvailable, setTimesAvailable] = useState([]);

  const { data: activeOrderData } = useQuery(GET_ACTIVE_ORDER, {
    fetchPolicy: "network-only", // Ensure fresh data
  });

  useQuery(GET_TIME_WINDOWS, {
    onCompleted: (data) => {
      setTimesAvailable(data.timeWindows);
    },
  });

  useEffect(() => {
    if (!activeOrderData?.activeOrder) {
      setOrderLines([]);
    }
    if (activeOrderData?.activeOrder?.lines) {
      setOrderLines(activeOrderData.activeOrder.lines);
    }
    if (activeOrderData?.activeOrder?.state === "ArrangingPayment") {
      moveStateToAddingItems();
    }
  }, [activeOrderData]);

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
  });

  const [setOrderTimeWindow, { loading: setTimeWindowLoader }] = useMutation(
    SET_ORDER_TIME_WINDOW,
    {
      onCompleted: (data) => {
        if (data?.setOrderTimeWindow?.errorCode) {
          setErrorHandlingState({
            showError: true,
            data: data?.setOrderTimeWindow?.message,
          });
        }
        setDate(data?.setOrderTimeWindow?.deliveryTime);
        setTimeDescription(data?.setOrderTimeWindow?.timeWindow?.description);
        setIsEdit(true);
      },
    }
  );

  useEffect(() => {
    if (!hourPicked[0]) return;

    setOrderTimeWindow({
      variables:
        { timeWindowId: hourPicked[0] }
    });
  }, [hourPicked]);

  useEffect(() => {
    setCloseAccordion(stage3DeliverTo);
  }, [stage3DeliverTo]);

  const onContinue = async () => {
    if (!hourPicked[0]) return;

    try {
      await setOrderTimeWindow({
        variables:
          { timeWindowId: hourPicked[0] }
      });
    } catch (err) {
      setIsEdit(false);
      setErrorHandlingState({
        showError: true,
        data: "An internal error has occurred",
      });
    }
  };

  const orderLinesFiltered = orderLines.filter((line) => !!line?.deliveryDate);
  const undeliverableItems = orderLines.filter((line) => !line?.deliveryDate);

  const groupedLines = Object.groupBy(
    orderLinesFiltered,
    ({ deliveryDate }) => deliveryDate
  );
  const groupedAndSorted = Object.entries(groupedLines).sort(
    ([a], [b]) => new Date(a).getTime() - new Date(b).getTime()
  );

  let startTime = "";
  let endTime = "";
  if (timeDescription) {
    startTime = timeDescription?.split("-")[0];
    endTime = timeDescription?.split("-")[1];
  }

  let deliveryTime = '';

  if (date) {
    const { preText, formattedDate } = formatDeliveryDate(date);
    deliveryTime = formattedDate ? t(`checkout.${preText}`, { formattedDate }) : t(`checkout.${preText}`);
  }

  return (
    <Accordion
      style={{
        borderRadius: "16px",
        padding: 30,
        boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
        marginBottom: 24,
        borderBottom: "none",
        background: "#FFF"
      }}
      expanded={closeAccordion}
      disabled={!stage3DeliverTo}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              opacity: stage3DeliverTo ? 1 : 0.64,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ScheduleIcon style={{
                  marginRight: t("css.side") !== "ltr" ? 0 : 16,
                  marginLeft: t("css.side") !== "ltr" ? 16 : 0,
                }} />
                <span style={{ ...h3 }}>{t("common.deliveryTime")}</span>
              </div>
              {!closeAccordion && (
                <div
                  style={{
                    ...h6Regular,
                    opacity: 0.8,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ ...h6Regular, opacity: 0.8 }}>
                    {`
                      ${t('common.arriving')}
                      ${getDateInfo(date, t("css.side") === "ltr" ? 'en' : 'he').customFormattedDate}
                      ${t('checkout.fromTo', { startTime, endTime })}
                    `}
                  </span>
                </div>
              )}
            </div>
            <div>
              {isEdit && !closeAccordion ? (
                <span
                  style={{
                    ...h6Medium,
                    color: "#80BB34",
                  }}
                  onClick={() => {
                    setCloseAccordion(true);
                    onEditDeliveryTime();
                  }}
                >
                  {t("common.edit")}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {orderLines?.length ? (
          <Typography>
            {groupedAndSorted?.length ? (
              <>
                <div style={{ paddingBottom: 40 }}>
                  <span style={{ ...h4Medium, ...flex, flexDirection: 'column', gap: 12 }}>
                    {t('checkout.preferredTime')}
                  </span>
                  {timeDescription ? <span style={{ ...h6Regular }}>
                    {deliveryTime} {t('checkout.fromTo', { startTime, endTime })}
                  </span> : null}
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '32px', // Spacing between children
                    width: '100%', // Full width of the parent
                    overflowX: 'auto',
                    overflowY: 'hidden',
                  }}
                >
                  {groupedAndSorted?.map(([date, items], idx) => {
                    const { customFormattedDate } = getDateInfo(date, t("css.side") === "ltr" ? 'en' : 'he');
                    return (
                      <div
                        key={idx}
                        style={{
                          flex: '0 0 calc((100% - 2 * 32px) / 3)', // Ensure only 3 children fit in the parent width
                          minWidth: 'calc((100% - 2 * 32px) / 3)', // Same as flex-basis to align properly
                        }}
                      >
                        {<span style={{ ...h5Medium }}>{customFormattedDate}</span>}
                        <DeliveryScheduler
                          pickDays={pickDays}
                          onHourPicked={(hour) => onHourPicked({ ...hourPicked, [idx]: hour })}
                          date={date}
                          timesAvailable={timesAvailable}
                        />
                      </div>
                    );
                  })}
                </div>

                {groupedAndSorted?.map(([date, items], idx) => {
                  const currentIndexForTimeAvailable = hourPicked[idx];
                  const fromTo = timesAvailable[+currentIndexForTimeAvailable - 1]?.description || "8:00 - 11:00";
                  return (
                    <Parcel
                      key={idx}
                      number={idx}
                      parcelLines={items}
                      isoDate={date}
                      startTime={startTime}
                      endTime={endTime}
                      isMoreThanOneParcel={groupedAndSorted?.length > 1}
                      hourPicked={hourPicked}
                      fromTo={fromTo}
                    ></Parcel>
                  );
                })}
                <button
                  className={classes.button}
                  style={{
                    background: hourPicked !== null ? "#80BB34" : "lightgray",
                    marginTop: 32,
                  }}
                  onClick={() => {
                    setProcesses();
                    setCloseAccordion(false);
                    onContinue();
                  }}
                  disabled={!hourPicked}
                >
                  {setTimeWindowLoader ? (
                    <Loader color={"#B73232"} size={30} />
                  ) : (
                    t("common.Continue")
                  )}
                </button>
              </>
            ) : (
              <UnavailableDeliveryItems undeliverableItems={undeliverableItems} />
            )}
          </Typography>
        ) : (
          <Typography>
            <div className={classes.emptyContainer}>
              <div className={classes.title}>
                <span className={classes.empty}>{t("cart.Empty")}</span>
                <span className={classes.empty}>
                  {t("cart.Check our catalog")}
                </span>
              </div>
              <EmptyIcon />
              <Link to="/catalog" className={classes.button}>
                {t("itemPage.catalog")}
              </Link>
            </div>
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DeliveryProcess;
