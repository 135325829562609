import React from "react";
import { useTranslation } from "react-i18next";

const Currency: React.FC = () => {
  const { t } = useTranslation();

  return (
    <span style={{ fontFamily: "Assistant" }}>{t('currency.current')}</span>
  );
};

export default Currency;
