import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import { h5Medium, h5Regular, h7Regular } from "../../../shared/constants";
import { useHistory } from "react-router-dom";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { ReactComponent as MinusIcon } from "../../../assets/minus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_TO_CART,
  GET_ACTIVE_ORDER,
  GET_TOTAL_LINES,
  REMOVE_FROM_CART,
  REMOVE_ORDER_LINE,
} from "../../../queries/cart.queries";
import Loader from "../../common/loader/loader.component";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { useTranslation } from "react-i18next";
import Currency from '../../../shared/components/currency/Currency';

const useStyles = makeStyles({
  container: {
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "36px",

    "@media (max-width:940px)": {
      width: "100%",
    },
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "none",
    width: 112,
    height: 40,
    background: "#E8E8E8",
    borderRadius: 8,
  },

  amount: {
    ...h5Regular,
    opacity: 0.64,
    maxWidth: 35,
    border: "none",
    textAlign: "center",
    backgroundColor: "#E8E8E8",
  },

  amountUneditable: {
    ...h5Regular,
    opacity: 0.64,
    maxWidth: 35,
    border: "none",
    textAlign: "center",
    paddingBottom: 20
  },

  price: {
    width: 80,
    height: 24,
    ...h5Medium,
    opacity: 0.72,
  },

  rightContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
  },

  qty: {
    ...h7Regular,
    display: "flex",
    justifyContent: "center",
    opacity: 0.4,
  },
});

const CartActions = ({ item, shouldDisplayActions = true }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const totalPerItem = (item?.linePriceWithTax / 100).toFixed(2);

  const [amountValue, setAmountValue] = useState(+item.quantity);

  const { data: activeOrder } = useQuery(GET_ACTIVE_ORDER);

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    onCompleted: async () => await addToCart(),
  });

  const addItemToOrder = async () => {
    if (
      !activeOrder?.activeOrder ||
      activeOrder?.activeOrder.state === "AddingItems"
    ) {
      await addToCart();
    }
    if (activeOrder?.activeOrder.state === "ArrangingPayment") {
      await moveStateToAddingItems();
    }
  };

  const [addToCart, { loading: addedLoading }] = useMutation(ADD_TO_CART, {
    variables: {
      variantId: item.productVariant.id,
      qty: 1,
    },
    refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
  });

  const [removeFromCart, { loading: adjustLineLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: item.id,
        qty: amountValue - 1,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const [removeOrderLine, { loading: lineRemovedLoading }] = useMutation(
    REMOVE_ORDER_LINE,
    {
      variables: {
        id: item?.id,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const [adjustOnChange, { loading: adjustOnChangeLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: item.id,
        qty: amountValue,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const onEditCartAmount = async (bol) => {
    if (isNaN(amountValue)) return;

    if (bol) {
      await addItemToOrder();
      setAmountValue(amountValue + 1);
    } else {
      await removeFromCart();
      setAmountValue(amountValue - 1);
    }
  };

  const onRemoveItem = async () => {
    await removeOrderLine();
  };

  const changeOrderQty = async (qty) => {
    if (isNaN(qty)) return;

    if (qty === 0) {
      await setAmountValue(+qty);
      await removeOrderLine();
      return;
    }

    if (qty !== 0 && amountValue !== 0) {
      await setAmountValue(+qty);
      await adjustOnChange();
    }
  };

  const minusIcon =
    addedLoading ||
      lineRemovedLoading ||
      adjustLineLoading ||
      adjustOnChangeLoading ? (
      <div style={{ width: "18%" }}>
        <Loader color={"#B73232"} size={15} />
      </div>
    ) : (
      <MinusIcon
        style={{ width: "18%" }}
        className="pointer"
        onClick={() => onEditCartAmount(false)}
      />
    );

  const plusIcon =
    addedLoading ||
      lineRemovedLoading ||
      adjustLineLoading ||
      adjustOnChangeLoading ? (
      <div style={{ width: "18%" }}>
        <Loader color={"#B73232"} size={15} />
      </div>
    ) : (
      <PlusIcon
        style={{ width: "18%" }}
        className="pointer"
        onClick={() => onEditCartAmount(true)}
      />
    );

  return (
    <div className={classes.container}>
      {shouldDisplayActions ? <div>
        <button className={classes.button}>
          {amountValue === 1 || amountValue === 0 ? (
            <>
              {amountValue === 1 ? (
                <DeleteIcon
                  style={{ width: 16, height: 16 }}
                  className="pointer"
                  onClick={() => onEditCartAmount(false)}
                />
              ) : (
                <div style={{ width: "18%" }}></div>
              )}
            </>
          ) : (
            minusIcon
          )}
          <input
            className={classes.amount}
            value={amountValue}
            type="number"
            min="0"
            max="99"
            onChange={(e) => {
              if (isNaN(e.target.value)) return;
              if (adjustOnChangeLoading) return;
              // setAmountValue(e.target.valueAsNumber);
              changeOrderQty(e.target.valueAsNumber);
            }}
          />
          {amountValue === 0 ? <div style={{ width: "18%" }}></div> : plusIcon}
        </button>
        <span className={classes.qty}>{t("cart.quantity")}</span>
      </div> : <span className={classes.amountUneditable}>{amountValue}</span>}
      <div className={classes.rightContainer}>
        <span className={classes.price}><Currency />{totalPerItem}</span>
        <i
          onClick={onRemoveItem}
          className="pointer"
          style={{ width: 16, height: 16 }}
        >
          {lineRemovedLoading ? (
            <Loader color={"#B73232"} size={15} />
          ) : (
            <DeleteIcon style={{ width: 16, height: 16 }} />
          )}
        </i>
      </div>
    </div>
  );
};

export default CartActions;
