import React from "react";

import { makeStyles } from "@material-ui/core";
import { CircleSpinner } from "react-spinners-kit";

interface CircleLoaderProps {
    size: number;
    color: string;
}

const useStyles = makeStyles({
    loader: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

const CircleLoader: React.FC<CircleLoaderProps> = ({ size, color }, props) => {
    const classes = useStyles();

    return (
        <div className={classes.loader} {...props}>
            <CircleSpinner color={color} size={size} loading={true} />
        </div>
    );
};

export default CircleLoader;